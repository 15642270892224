<script>

export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.$watch( () => this.title, () => {
      const headerInput = this.$el.querySelector('input')
      headerInput.value = this.value
      this.updateWidth({ target: headerInput })
    })
  },
  methods: {
    updateWidth( ev ) {
      ev.target.parentNode.dataset.value = ev.target.value;
      this.$emit('input', ev)
    },
    blur( ev ) {
      this.$emit('blur', ev)
    }
  }
}

</script>


<template>
  <div class="wrapper-auto-grow">
    <input 
    type="text"
    class="input-auto-grow header-input" 
    :value="value"
    @input="updateWidth"
    @blur="blur" />
  </div>
</template>


<style scoped>
.wrapper-auto-grow {
  display: inline-grid;
  width: max-content;
}

.wrapper-auto-grow::after,
.wrapper-auto-grow .input-auto-grow {
  /* width: auto; */
  /* min-width: 1em; */
  grid-area: 1/2;
  font: inherit;
  padding: 0.5rem ;
}

.wrapper-auto-grow::after {
  content: attr(data-value) "";
  visibility: hidden;
  white-space: pre-wrap;
}



</style>